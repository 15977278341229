<template>
  <div class="record-page">
    <Head title="出价记录" />
    <Warpper top="40">
      <div class="page-loadmore-wrapper" style="overflow: scroll;height: 100%;">
        <mt-loadmore
          :bottom-method="loadBottom"
          @bottom-status-change="handleBottomChange"
          :bottom-all-loaded="allLoaded"
          ref="loadmore"
          :auto-fill="false"
        >

          <ul class="page-loadmore-list record-list">
            <li v-for="(item,index) in list" :key="index" :class="{'active':item.winer==1}">
              <div class="info">
                <div class="pic">
                  <img :src="item.headpic" alt />
                </div>
                <div class="content">
                  <h5>{{item.touser_nickname}}</h5>
                  <p>{{item.times}}</p>
                </div>
              </div>
              <p>¥{{item.price}}<span class="weituo-tag" v-if="item.weituo>0">(委托)</span></p>
            </li>
          </ul>

          <div slot="bottom" class="mint-loadmore-bottom">
            <span
              v-show="bottomStatus !== 'loading'"
              :class="{ 'is-rotate': bottomStatus === 'drop' }"
            >↑</span>
            <span v-show="bottomStatus === 'loading'">
              <mt-spinner type="snake"></mt-spinner>
            </span>
          </div>
        </mt-loadmore>
      </div>
    </Warpper>
  </div>
</template>

<script>
import { reqBuyList } from "@/network/api";
export default {
  name: "record",
  components: {},
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.loadBottom();
  },
  data() {
    return {
      allLoaded: false,
      bottomStatus: "",
      wrapperHeight: 0,
      list: [],
      page:0,
      total:1,
      first:true
    };
  },
  methods: {
    handleBottomChange(status) {
      this.bottomStatus = status;
    },
    loadBottom() {
      reqBuyList({
        id:this.id,
        page: this.page+1,
        limit: 10
      }).then(res => {
        const { code, msg, backdata } = res;
        if (code != 0) return this.$toast({ message: msg });
        this.page = backdata.now;
        this.total = backdata.count;
        this.list = [...this.list,...backdata.list];
        if(this.page>=this.total){
          this.allLoaded = true;
        }
       if (!this.first) {
          this.$refs.loadmore.onBottomLoaded();
        }
        this.first = false;
      })
    }
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
.weituo-tag{
  font-size: .2rem;
}
.record-list > li {
  box-sizing: border-box;
  padding: 0.44rem 0.29rem;
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-of-type)::after {
    content: "";
    display: block;
    bottom: 0;
    left: 0.3rem;
    right: 0;
    height: 1px;
    background: #e1e1e1;
    position: absolute;
    transform: scaleY(0.5);
  }
  p {
    font-size: 0.32rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  &.active {
    p {
      color: #ff1010;
    }
    .info {
      .pic {
        border: 0.02rem solid #fed530;
        &::before {
          content: "";
          display: block;
          width: 0.34rem;
          height: 0.3rem;
          position: absolute;
          left: -0.11rem;
          top: -0.18rem;
          background: url("../../assets/images/no1.png") no-repeat;
          background-size: 100% auto;
          z-index: 1;
        }
      }
    }
  }
  .info {
    display: flex;
    align-items: center;
    .pic {
      background: #dcdcdc;
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      position: relative;
      margin-right: 0.14rem;
      box-sizing: border-box;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .content {
      h5 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.28rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-bottom: 0.1rem;
      }
      p {
        font-size: 0.22rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
.mint-loadmore-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>